import { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

/* icons */
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';

import LanguageIcon from '@mui/icons-material/Language';

import HelpOutlineIcon from '@mui/icons-material/HelpOutlined';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
/* icons */

import TripitoLogo from '../../../../../assets/Tripito-logo-white-text.svg';

import authService from '../../../../services/api/auth.service';
import useStore from 'store';
//import { AppCtx } from '../../../../app';

const LogoImg = styled('img')(({ theme }) => ({
  width: 96,
  marginLeft: theme.spacing(1),
}));

const RestyledRouterLink = styled(RouterLink)`
  && {
    text-decoration: none;
  }
`;

const EmptySpace = styled('div')`
  flex-grow: 1;
`;

const topBarIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.white,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const WebBtn = styled(topBarIconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
}));

const HelpBtn = styled(topBarIconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
}));

const SignOutBtn = styled(topBarIconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

interface TopbarProps {
  className?: string;
  onSidebarOpen: (o?: any) => void;
  [x: string]: unknown;
}

export function Topbar(props: TopbarProps) {
  const { className, onSidebarOpen, ...rest } = props;

  //const ctx = useContext(AppCtx);
  const [setUser, setMenuOpen] = useStore((state) => [
    state.setUser,
    state.setMenuOpen,
  ]);

  const setConfirmationDialog = useStore(
    (state) => state.setConfirmationDialog
  );

  const logout = () => {
    authService.logout();
    setUser(null);
    setMenuOpen(false);
    /*ctx.setUser(null);
    ctx.setMenuOpen(false);*/
  };

  const showConfirmation = () => {
    setConfirmationDialog({
      open: true,
      question: 'Naozaj sa chcete odhlásiť?',
      onPositive: logout,
    });
  };

  return (
    <AppBar className={className} color="secondary" {...rest}>
      <Toolbar>
        <RestyledRouterLink to="/">
          <LogoImg alt="a logo" src={TripitoLogo} />
        </RestyledRouterLink>
        <EmptySpace />

        <Tooltip title="www.tripito.eu">
          <WebBtn
            onClick={() => window.open('https://www.tripito.eu', '_blank')}
          >
            <LanguageIcon />
          </WebBtn>
        </Tooltip>

        <EmptySpace />

        <Tooltip title="Pomoc">
          <HelpBtn>
            <HelpOutlineIcon />
          </HelpBtn>
        </Tooltip>

        <Hidden mdDown>
          <Tooltip title="Odhlásiť sa">
            <SignOutBtn onClick={showConfirmation}>
              <ExitToAppIcon />
            </SignOutBtn>
          </Tooltip>
        </Hidden>

        <Hidden lgUp>
          <IconButton
            onClick={() => onSidebarOpen()}
            //disabled= when app is loading
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;
