import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: fit-content;
`;

const StyledAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
  font-size: 1rem;
`;

const StyledName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

interface IProfileProps {
  name: string;
  email: string;
  avatar: string;
  role: string;
}

export function Profile(props: IProfileProps) {
  /*const user = {
    email: 'info@tripito.eu',
    avatar: 'Tripito',
  };*/
  const { name, email, avatar, role } = props;

  const capitalisedRole =
    role && role.charAt(0).toUpperCase() + role.slice(1).toLocaleLowerCase();

  return (
    <Root>
      <RouterLink to="/">
        <StyledAvatar alt={email} src={avatar}>
          {name}
        </StyledAvatar>
      </RouterLink>
      <StyledName variant="h6">{name}</StyledName>
      <Typography variant="body2">{capitalisedRole}</Typography>
    </Root>
  );
}

export default Profile;
