import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Typography from '@mui/material/Typography';

import TripitoLogo from '../../../../../assets/Tripito-logo-white-text.svg';

const LogoImg = styled('img')(({ theme }) => ({
  width: 96,
  marginLeft: theme.spacing(1),
}));

const RestyledRouterLink = styled(RouterLink)`
  && {
    text-decoration: none;
  }
`;

interface TopbarProps {
  className?: string;
  [x: string]: unknown;
}

export function Topbar(props: TopbarProps) {
  const { className, ...rest } = props;

  return (
    <AppBar {...rest} color="secondary" position="sticky">
      <Toolbar>
        <RestyledRouterLink to="/">
          <LogoImg alt="a logo" src={TripitoLogo} />
        </RestyledRouterLink>
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;