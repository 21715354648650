import { ReactNode } from 'react';
import { styled, Theme } from '@mui/material/styles';

const Row = styled('div')(({ theme }) => ({
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
  //paddingInline: theme.spacing(2),
}));

const Spacer = styled('span')`
  flex-grow: 1;
`;

/*const spacing1 = (theme: Theme) => ({
  marginRight: theme.spacing(1),
});*/

interface DataViewToolbarProps {
  loading: boolean;
  firstRow?: ReactNode;
  secondRow?: ReactNode;
}

export function DataViewToolbar(props: DataViewToolbarProps) {
  const { loading, firstRow, secondRow } = props;
  //const loading = true;

  return (
    <div>
      <Row>
        <Spacer />
        {/*{loading ? <LinearProgress /> : null}
        <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>*/}
        {firstRow}
      </Row>
      {/*<Row>
        <<SearchInput
          sx={spacing1}
          placeholder="Vyhľadať dopyt"
          onChange={() => {
            console.log('a');
          }}
          //disabled={appIsLoading}
        />
        <Tooltip title="Aktualizovať">
          <IconButton
            aria-label="refresh"
            sx={spacing1}
            //onClick={handleRefresh}
          >
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        
        </Row>*/}
    </div>
  );
}

export default DataViewToolbar;
