import { styled } from '@mui/material/styles';
import React, { forwardRef } from 'react';

import { NavLink as RouterLink } from 'react-router-dom';

import {
  List as MuiList,
  ListItem as MuiListItem,
  Button as MuiButton,
  Hidden,
  Divider,
  colors,
} from '@mui/material';

const List = styled(MuiList)``;

const ListItem = styled(MuiListItem)`
  display: 'flex';
  padding-top: 0;
  padding-bottom: 0;
`;

const Button = styled(MuiButton)(({ theme }) => ({
  padding: '10px 8px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium,

  active: {
    '& icon': {
      color: theme.palette.primary.light,
    },
  },
})) as typeof MuiButton;

const IconWrapper = styled('div')(({ theme }) => ({
  //color: theme.palette.primary.main, //theme.palette.icon,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}));

const CustomRouterLink = forwardRef(
  (
    props: { to: string; children: React.ReactNode },
    ref: React.LegacyRef<HTMLDivElement>
  ) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  )
);

interface SidebarNavProps {
  items: Array<ItemProps>;
  [x: string]: any;
}

interface ItemProps {
  path?: string;
  logOut?: boolean;
  icon: React.ReactNode;
  title: string;
  onClick?: () => void;
}

export function SidebarNav(props: SidebarNavProps) {
  const { items, ...rest } = props;

  return (
    <List {...rest}>
      {items.map((item: ItemProps) => {
        const component = item.path ? CustomRouterLink : 'div';

        const Item = () => (
          <ListItem
            disableGutters
            sx={{
              ...(item.logOut ? { marginTop: '35px' } : {}),
            }}
          >
            <Button component={component} to={item.path} onClick={item.onClick}>
              <IconWrapper>{item.icon}</IconWrapper>
              {item.title}
            </Button>
          </ListItem>
        );

        return (
          <div key={item.title}>
            {item.logOut ? (
              <Hidden mdUp>
                <Divider />
                <Item />
              </Hidden>
            ) : (
              <Item />
            )}
          </div>
        );
      })}
    </List>
  );
}

export default SidebarNav;
