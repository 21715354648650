/*import { makeStyles } from '@mui/material';*/
//import { makeStyles, createStyles } from '@mui/styles';

import { styled } from '@mui/material/styles';
import { Topbar } from './components';

const Root = styled('div')`
  padding-top: 64;
  height: 100%;
`;

const Content = styled('main')`
  height: 100%;
`;

interface MinimalProps {
  children: React.ReactNode;
}

export function Minimal(props: MinimalProps) {
  const { children } = props;

  return (
    <Root>
      <Topbar />
      <Content>{children}</Content>
    </Root>
  );
}

export default Minimal;
